import {
  Home,
  Login,
  Register,
  Dashboard,
  Message,
  Operations,
  Profile,
  Search,
  Calendar,
  DoctorDetail,
  SearchBody,
  NotFound,
  ForgotPassword,
  ComingSoon,
  DoctorRegister,
  Contact,
  SuccessDoctor,
  HospitalRegister,
  OperationDetail,
  Credit,
  RegisterStepper,
  Blog,
  BlogDetail,
  FeedBackOperation,
} from "../containers";

export const routes = [
  {
    title: "Home",
    path: "/",
    component: <Home />,
    protected: false,
  },
  {
    title: "Contact",
    path: "/contact",
    component: <Contact />,
    protected: false,
  },
  {
    title: "Blog",
    path: "/blog",
    component: <Blog />,
    protected: false,
  },
  {
    title: "BlogDetail",
    path: "/blog/:id",
    component: <BlogDetail />,
    protected: false,
  },
  {
    title: "Login",
    path: "/login",
    component: <Login />,
    protected: false,
  },
  {
    title: "Forgot Password",
    path: "/forgot-password",
    component: <ForgotPassword />,
    protected: false,
  },
  {
    title: "Forgot Password",
    path: "/passwordreset",
    component: <ForgotPassword />,
    protected: false,
  },
  {
    title: "Register",
    path: "/register",
    component: <RegisterStepper />,
    protected: false,
  },
  {
    title: "Doctor Register",
    path: "/doctor/register",
    component: <DoctorRegister />,
    protected: false,
  },
  {
    title: "Hospital Register",
    path: "/hospital/register",
    component: <HospitalRegister />,
    protected: false,
  },
  {
    title: "Success Doctor",
    path: "/doctor/success",
    component: <SuccessDoctor />,
    protected: false,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  {
    title: "Message",
    path: "/message",
    component: <Message />,
    protected: true,
  },
  {
    title: "Operations",
    path: "/operations",
    component: <Operations />,
    protected: true,
  },
  {
    title: "OperationDetail",
    path: "/operation/:id",
    component: <OperationDetail />,
    protected: true,
  },
  {
    title: "Profile",
    path: "/profile",
    component: <Profile />,
    protected: true,
  },
  {
    title: "Search",
    path: "/search",
    component: <Search />,
    protected: false,
  },
  {
    title: "Calendar",
    path: "/calendar",
    component: <Calendar />,
    protected: true,
  },
  {
    title: "Feedback Operation",
    path: "/feedback-operation",
    component: <FeedBackOperation />,
    protected: true,
  },
  {
    title: "DoctorDetail",
    path: "/doctor/:id",
    component: <DoctorDetail />,
    protected: false,
  },
  // {
  //   title: "Search Body",
  //   path: "/search-body",
  //   component: <SearchBody />,
  //   protected: true,
  // },
  {
    title: "Credit",
    path: "/credit",
    component: <Credit />,
    protected: true,
  },
  {
    title: "404",
    path: "*",
    component: <NotFound />,
    protected: false,
  },
];
