import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import style from "./contentpopup.module.scss";
import { FileIcon } from "../../assets/icon";

const ContentPopup = ({
  closeClick,
  url,
  isNavigate,
  leftClick,
  rightClick,
}) => {
  return (
    <div className={style.contentPopup}>
      <button onClick={closeClick} className={style.close}>
        X
      </button>

      <div className={style.contentWrapper}>
        {isNavigate ? (
          <div className={style.buttonWrapper}>
            <button onClick={leftClick}>
              <ArrowLeftIcon />
            </button>
            <button onClick={rightClick}>
              <ArrowRightIcon />
            </button>
          </div>
        ) : null}
        {url.split(".")[url.split(".").length - 1].toLowerCase() === "mp4" ? (
          <video autoPlay muted playsInline>
            <source src={url} type="video/mp4" />
          </video>
        ) : url.split(".")[url.split(".").length - 1] === "pdf" ? (
          <a href={url} target="_blank" className={style.pdfLink}>
            <FileIcon />
            PDF
          </a>
        ) : (
          <img key={url} src={url} />
        )}
      </div>
    </div>
  );
};

export default ContentPopup;
