import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Blog1 from "../../../assets/img/blog/blog-1.png";
import Blog2 from "../../../assets/img/blog/blog-2.png";
import Blog3 from "../../../assets/img/blog/blog-3.png";
import PersonImage from "../../../assets/img/blog/person.png";
import TitleWrapper from "../TitleWrapper/TitleWrapper";
import style from "./blog.module.scss";
import { LangData } from "../../../data/LangData";
import axios from "axios";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import moment from "moment";

const Card = ({
  image,
  title,
  text,
  personImage,
  date,
  person,
  lang,
  slug,
}) => {
  return (
    <a href={`/blog/${slug}`} className={style.card}>
      <img className={style.cover} src={image} />
      <div className={style.bottomWrapper}>
        <div className={style.textWrapper}>
          <h4>{title}</h4>
          <p>{text}</p>
        </div>
        <div className={style.bottom}>
          <div className={style.personWrapper}>
            {/*   <img src={personImage} />*/}
            <h5>{person}</h5>
          </div>
          <p className={style.date}>{moment(date).format("DD.MM.YYYY")}</p>
        </div>
        <a href={`/blog/${slug}`} className={style.readMore}>
          {LangData[lang].landing.blog.readMore}
        </a>
      </div>
    </a>
  );
};

const Blog = ({ dashboard }) => {
  const [data, setData] = useState();
  const [isOpen, setOpen] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.search.blog}`)
      .then((res) => {
        console.log("blogData", res.data);
        setData(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("blogData", data);

  return (
    <div className={`${style.wrapper} ${dashboard ? style.dashboard : null}`}>
      {dashboard ? null : (
        <TitleWrapper
          title={LangData[lang].landing.blog.title}
          subTitle={LangData[lang].landing.blog.subtitle}
        />
      )}
      <div className={style.cardWrapper}>
        {data && data.length
          ? data.slice(data.length - 3, data.length).map((item, index) => {
              return (
                <Card
                  title={item.title}
                  text={item.shortdesc
                    .replace("<p>", "")
                    .replace("</p>", "")
                    .replace("[&hellip;]", "")}
                  person={item.writer}
                  date={item.date.split("T")[0]}
                  personImage={PersonImage}
                  image={item.image}
                  lang={lang}
                  slug={item.slug}
                />
              );
            })
          : null}
      </div>
      <a className={style.allButton} href="/blog">
        {LangData[lang].landing.blog.title}
      </a>
    </div>
  );
};

export default Blog;
