import { ContentPopup, Loading, Sidebar, TopBar } from "../../components";
import {
  FlightIcon,
  LocationIcon,
  OperationIcon,
  OperationCardIcon,
  FolderIcon,
  DownloadIcon,
  StarIcon,
} from "../../assets/icon";

import DoctorImage from "../../assets/img/doctor-image.png";

import Medical1 from "../../assets/img/dashboard/operation/medical-1.png";
import Medical2 from "../../assets/img/dashboard/operation/medical-2.png";
import Medical3 from "../../assets/img/dashboard/operation/medical-3.png";
import Medical4 from "../../assets/img/dashboard/operation/medical-4.png";
import style from "./feedbackoperation.module.scss";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import PopupCalendar from "../Calendar/PopupCalendar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { CheckImage } from "../../helpers/CheckImage";
import { LangData } from "../../data/LangData";

const Card = ({
  operation,
  doctorImage,
  doctorName,
  icon,
  location,
  time,
  startClick,
}) => {
  const { width } = useWindowDimensions();
  const [selectedStar, setSelectedStar] = useState(0);
  return (
    <div className={style.card}>
      <div className={style.left}>
        <div className={style.doctor}>
          <img src={doctorImage} alt="doctor" />
          <h5>{"Doc. Dr. Stephan"}</h5>
        </div>
        <div className={style.operation}>
          <div className={style.icon}>{icon}</div>
          <h5>{operation}</h5>
        </div>
        <div className={style.flight}>
          <div className={style.icon}>
            <FlightIcon />
          </div>
          <div className={style.info}>
            <h6>{location}</h6>
            <p>{time}</p>
          </div>
        </div>
      </div>
      <div className={style.right}>
        <button
          onClick={() => {
            setSelectedStar(1);
            startClick();
          }}
          className={selectedStar > 0 && style.active}
        >
          <StarIcon type={selectedStar > 0 && "on"} />
        </button>
        <button
          onClick={() => {
            setSelectedStar(2);
            startClick();
          }}
          className={selectedStar > 1 && style.active}
        >
          <StarIcon type={selectedStar > 1 && "on"} />
        </button>
        <button
          onClick={() => {
            setSelectedStar(3);
            startClick();
          }}
          className={selectedStar > 2 && style.active}
        >
          <StarIcon type={selectedStar > 2 && "on"} />
        </button>
        <button
          onClick={() => {
            setSelectedStar(4);
            startClick();
          }}
          className={selectedStar > 3 && style.active}
        >
          <StarIcon type={selectedStar > 3 && "on"} />
        </button>
        <button
          onClick={() => {
            setSelectedStar(5);
            startClick();
          }}
          className={selectedStar > 4 && style.active}
        >
          <StarIcon type={selectedStar > 4 && "on"} />
        </button>
      </div>
    </div>
  );
};

const FeedBackOperation = () => {
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.settings.setting.lang);
  const userData = useSelector((state) => state.user.user);
  const [isPopup, setPopup] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [contentLink, setContentLink] = useState({
    url: "",
    type: "",
  });

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.operation.getOperationList}`)
      .then((res) => {
        setData(JSON.parse(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={style.operationsContainer}>
          <Sidebar
            isOpen={sideBar || width > 940}
            closeClick={() => setSidebar(false)}
            searchTrigger={() => {}}
          />
          {isPopup ? (
            <PopupCalendar
              lang={lang}
              type={isPopup}
              closeClick={() => setPopup()}
            />
          ) : null}
          {contentLink.url && contentLink.type ? (
            <ContentPopup
              closeClick={() =>
                setContentLink({
                  url: "",
                  type: "",
                })
              }
              url={contentLink.url}
              type={contentLink.type}
            />
          ) : null}

          <TopBar hamburgerClick={() => setSidebar(true)} />

          <div className={style.operationCard}>
            <div className={style.top}>
              <h1 className={style.title}>{LangData[lang].feedBack.title}</h1>
            </div>
            <div className={style.cardWrapper}>
              {data && data.length ? (
                data.map((item) => {
                  return (
                    <Card
                      time={item.time}
                      icon={item.icon}
                      operation={item.operation}
                      location={item.location}
                      doctorImage={item.doctorImage}
                      doctorName={item.doctorName}
                      startClick={() => {}}
                    />
                  );
                })
              ) : (
                <h5 className={style.empty}>
                  {LangData[lang].operations.empty}
                </h5>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedBackOperation;
