import { DeletePopup, Loading, Sidebar, TopBar } from "../../components";
import style from "./profile.module.scss";
import EmptyPhoto from "../../assets/img/empty-photo.png";
import { ImageIcon } from "../../assets/icon";
import {
  TextInput,
  EmailInput,
  PhoneInput,
  DateInput,
  PasswordInput,
  SelectInput,
  RegionInput,
  CityInput,
  ProfileImageInput,
  TextAreaInput,
  FileAddInput,
  ReferenceInput,
  OperationAddInput,
  OperationInput,
  RewardInput,
} from "../../components/Input/Input";
import { AuthButton } from "../../components/Button/Button";
import { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import useWindowDimensions from "../../helpers/windowWidth";
import { useSelector } from "react-redux";
import { LangData } from "../../data/LangData";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import DoctorDetail from "../DoctorDetail/DoctorDetail";

const Spinner = () => {
  return (
    <div class={style.ldsRipple}>
      <div></div>
      <div></div>
    </div>
  );
};

const DoctorForm = ({ lang, data }) => {
  const RegisterSchema = Yup.object().shape({
    professionTitle: Yup.string().required(
      `${LangData[lang].errors.mandatoryfield}`
    ),
  });

  const [error, setError] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState();
  const [diploma, setDiploma] = useState();
  const [health, setHealth] = useState();
  const [operationFile, setOperationFile] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState([]);
  const [isLoad, setLoad] = useState(false);
  const userData = useSelector((state) => state.user.user);

  const [isDeleteOpen, setDeleteOpen] = useState(false);

  const [update, setUpdate] = useState({
    profilePhoto: false,
    diploma: false,
    health: false,
    operation: false,
    reward: false,
    reference: false,
    beforeOperation: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (data.doctorProfile) {
      if (
        data.doctorProfile.doctorAwards &&
        data.doctorProfile.doctorAwards.length
      ) {
        setRewardData(
          data.doctorProfile.doctorAwards.map((i) => {
            return {
              caption: i.award.Caption,
              detail: i.award.Detail,
              file: i.documents[0],
            };
          })
        );
      }

      if (
        data.doctorProfile.doctorReferences &&
        data.doctorProfile.doctorReferences.length
      ) {
        setReferenceData(
          data.doctorProfile.doctorReferences.map((i) => {
            return {
              reference: i.Reference,
              caption: i.Caption,
              detail: i.Detail,
            };
          })
        );
      }

      if (
        data.doctorProfile.doctorOperations &&
        data.doctorProfile.doctorOperations.length
      ) {
        setSelectedOperation(
          data.doctorProfile.doctorOperations.map((i) => {
            return {
              category: {
                id: i.CategoryId,
              },
              operation: {
                id: i.Id,
                name: i.Name,
              },
            };
          })
        );
      }

      if (
        data.doctorProfile.doctorDocuments &&
        data.doctorProfile.doctorDocuments.slice(3, 90).length
      ) {
        setOperationFile(
          data.doctorProfile.doctorDocuments.slice(3, 90).map((i) => {
            return {
              file: i.documents,
              title: i.title,
              id: i.id,
            };
          })
        );
      }
    }
  }, [data]);

  const checkError = () => {
    let errorArr = [];

    if (update.profilePhoto && !profilePhoto) {
      errorArr.push("profilePhoto");
    }

    if (update.diploma && !diploma) {
      errorArr.push("diploma");
    }

    if (update.health && !health) {
      errorArr.push("health");
    }

    if (!selectedOperation.length) {
      errorArr.push("operation");
    }

    if (errorArr.length) {
      setError(errorArr);
    } else {
      return true;
    }
  };

  const addDoctorInfo = async (value) => {
    if (update.reward && rewardData && rewardData.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addAward}?lang=${lang}`,
          rewardData.map((item) => {
            return {
              doctorId: data.doctorProfile.doctorCv.DoctorId,
              caption: item.caption,
              detail: item.detail,
            };
          })
        )
        .then((res) => {
          console.log(res.data);

          if (rewardData.map((item) => item.file !== "").length) {
            for (let index = 0; index < rewardData.length; index++) {
              let formData = new FormData();

              formData.append("files", rewardData[index].file);

              axios
                .post(
                  `${ApiRoutes.auth.doctor.addFile}?id=${90 + index}&caption=${
                    rewardData[index].caption
                  }&lang=tr`,
                  formData
                )
                .then((res) => {
                  console.log(res.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (update.reference && referenceData && referenceData.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addReference}?lang=${lang}`,
          referenceData.map((item) => {
            return {
              doctorId: data.doctorProfile.doctorCv.DoctorId,
              reference: item.reference,
              detail: item.detail,
              caption: item.caption,
            };
          })
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (value) {
      await axios
        .post(`${ApiRoutes.auth.doctor.addCv}?lang=${lang}`, {
          id: data.doctorProfile.doctorCv.Id,
          doctorId: data.doctorProfile.doctorCv.DoctorId,
          cv: value.cv,
          professionTitle: value.professionTitle,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (update.operation && selectedOperation && selectedOperation.length) {
      await axios
        .post(
          `${ApiRoutes.auth.doctor.addOperation}?lang=${lang}`,
          selectedOperation.map((item) => {
            return {
              doctorId: data.doctorProfile.doctorCv.DoctorId,
              categoryId: parseFloat(item.category.id),
              id: parseFloat(item.operation.id),
              name: item.operation.name,
            };
          })
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (update.profilePhoto && profilePhoto) {
      let formDataProfile = new FormData();
      formDataProfile.append("files", profilePhoto);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=0&caption=profilePhoto&lang=tr`,
          formDataProfile
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (update.diploma && diploma) {
      let formDataDiploma = new FormData();
      formDataDiploma.append("files", diploma);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=1&caption=diploma&lang=tr`,
          formDataDiploma
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (update.health && health) {
      let formDataHealth = new FormData();
      formDataHealth.append("files", health);

      await axios
        .post(
          `${ApiRoutes.auth.doctor.addFile}?id=2&caption=healthDocument&lang=tr`,
          formDataHealth
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (update.beforeOperation && operationFile && operationFile.length) {
      for (let index = 0; index < operationFile.length; index++) {
        let formData = new FormData();

        for (
          let indexFile = 0;
          indexFile < operationFile[index].file.length;
          indexFile++
        ) {
          formData.append("files", operationFile[index].file[indexFile]);
        }

        await axios
          .post(
            `${ApiRoutes.auth.doctor.addFile}?id=${3 + index}&caption=${
              operationFile[index].title
            }&lang=tr`,
            formData
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    await navigate(0);
    // await navigate('/doctor/success');
  };

  const removeFile = (id, caption) => {
    axios
      .post(
        `${ApiRoutes.auth.doctor.removeFile}?id=${isDeleteOpen.id}&caption=${isDeleteOpen.title}&lang=tr`
      )
      .then((res) => {
        if (res.data && res.data.caption === "Success") {
          setDeleteOpen(false);
          navigate(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isDeleteOpen ? (
        <DeletePopup
          deleteClick={() => removeFile()}
          closeClick={() => setDeleteOpen(false)}
        />
      ) : null}
      {data ? (
        <div className={style.bottom}>
          <Formik
            initialValues={{
              cv: data.doctorProfile.doctorCv
                ? data.doctorProfile.doctorCv.CV
                : "",
              checked: false,
              professionTitle: data.doctorProfile.doctorCv
                ? data.doctorProfile.doctorCv.professionTitle
                : "",
            }}
            validationSchema={RegisterSchema}
            onSubmit={(values) => {
              // registerSubmit(values);
              if (checkError()) {
                addDoctorInfo(values);
                setLoad(true);
              }
            }}

            // innerRef={formikRef}
          >
            {({ errors, touched, values, handleSubmit }) => (
              <Form id="payment" onSubmit={handleSubmit}>
                <div className={style.inputWrapper}>
                  <div className={style.column}>
                    <h2 className={style.seperatorTitle}>
                      {LangData[lang].profile.doctorProfile}
                    </h2>

                    <TextAreaInput
                      name={"cv"}
                      value={values.cv}
                      title={LangData[lang].form.cv}
                      placeholder={LangData[lang].form.cvPlaceholer}
                    />

                    {errors.cv && touched.cv ? <span>{errors.cv}</span> : null}
                    <SelectInput
                      name={"professionTitle"}
                      doctor
                      value={values.professionTitle}
                      title={LangData[lang].form.professionTitle}
                      placeholder={LangData[lang].form.professionTitle}
                      valueData={[
                        {
                          name: `${LangData[lang].form.professionTitle} Seçiniz`,
                          value: "",
                        },
                        ...LangData[lang].form.professionSelect.map((item) => {
                          return {
                            name: item.name,
                            value: item.value,
                          };
                        }),
                      ]}
                    />
                    {errors.professionTitle && touched.professionTitle ? (
                      <span>{errors.professionTitle}</span>
                    ) : null}
                    <FileAddInput
                      change={(e) => {
                        setDiploma(e);
                        setUpdate({
                          ...update,
                          diploma: true,
                        });
                      }}
                      placeholder={LangData[lang].form.document}
                      value={data.doctorProfile.doctorDocuments[1]}
                      single
                    />
                    {error.includes("diploma") ? (
                      <span>Lütfen diploma ekleyin.</span>
                    ) : null}
                    <FileAddInput
                      change={(e) => {
                        setHealth(e);
                        setUpdate({
                          ...update,
                          health: true,
                        });
                      }}
                      placeholder={LangData[lang].form.healthTourismFile}
                      title={LangData[lang].form.healthTourismFile}
                      value={data.doctorProfile.doctorDocuments[2]}
                      single
                    />
                    {error.includes("health") ? (
                      <span>Lütfen sağlık turizm belgesi ekleyin.</span>
                    ) : null}
                    <RewardInput
                      addData={(e) => {
                        setRewardData([...rewardData, e]);
                        setUpdate({
                          ...update,
                          reward: true,
                        });
                      }}
                      data={rewardData}
                      deleteClick={(e) => {
                        setRewardData(
                          rewardData.filter((i, index) => index !== e)
                        );
                        setUpdate({
                          ...update,
                          reward: true,
                        });
                      }}
                    />
                    {errors.cv && touched.cv ? <span>{errors.cv}</span> : null}
                  </div>
                  <div className={style.line} />
                  <div className={style.column}>
                    {/* <ReferenceInput
                      addData={(e) => {
                        setReferenceData([...referenceData, e]);
                        setUpdate({
                          ...update,
                          reference: true,
                        });
                      }}
                      data={referenceData}
                      deleteClick={(e) => {
                        setReferenceData(
                          referenceData.filter((i, index) => index !== e)
                        );
                        setUpdate({
                          ...update,
                          reference: true,
                        });
                      }}
                    /> */}
                    <OperationInput
                      name={"reward"}
                      value={selectedOperation}
                      title={"Operation"}
                      placeholder="Operation giriniz."
                      addData={(e) => {
                        setSelectedOperation([...selectedOperation, e]);
                        setUpdate({
                          ...update,
                          operation: true,
                        });
                      }}
                      deleteClick={(e) => {
                        setSelectedOperation(
                          selectedOperation.filter((i, index) => index !== e)
                        );
                        setUpdate({
                          ...update,
                          operation: true,
                        });
                      }}
                    />
                    {error.includes("operation") ? (
                      <span>Lütfen operasyon seçimi yapınız.</span>
                    ) : null}

                    <OperationAddInput
                      fileChange={(e) => {
                        setOperationFile([...operationFile, e]);

                        setUpdate({
                          ...update,
                          beforeOperation: true,
                        });
                      }}
                      file={operationFile}
                      deleteClick={async (e) => {
                        const currentFile = await operationFile.filter(
                          (i, index) => index === e
                        );
                        await setDeleteOpen(currentFile[0]);

                        // setOperationFile(
                        //   operationFile.filter((i, indexI) => indexI !== e)
                        // );
                        // setUpdate({
                        //   ...update,
                        //   beforeOperation: true,
                        // });
                      }}
                    />
                    <button className={style.submit}>
                      {isLoad ? (
                        <Spinner />
                      ) : (
                        LangData[lang].calendar.popup.save
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};

const Profile = () => {
  const [isEmail, setEmail] = useState(false);
  const [isPhone, setPhone] = useState(false);
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const userData = useSelector((state) => state.user.user);
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isLoad, setLoad] = useState(true);
  const [editLoad, setEditLoad] = useState(false);
  const [success, setSuccess] = useState(false);

  const [data, setData] = useState();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    surName: Yup.string()
      .min(2, `${LangData[lang].errors.surnameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    phone: Yup.string()
      .matches(phoneRegExp, `${LangData[lang].errors.correctphone}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    birthDate: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    region: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    provice: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    district: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    address: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${LangData[lang].errors.repeatpassword}`
      )
      .required(`${LangData[lang].errors.mandatoryfield}`),
  });

  const [error, setError] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState();
  const [diploma, setDiploma] = useState();
  const [health, setHealth] = useState();
  const [operationFile, setOperationFile] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [referenceData, setReferenceData] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState([]);
  const [isEdit, setEdit] = useState(false);

  const checkError = () => {
    let errorArr = [];

    if (!profilePhoto) {
      errorArr.push("profilePhoto");
    }

    if (!diploma) {
      errorArr.push("diploma");
    }

    if (!health) {
      errorArr.push("health");
    }

    if (!selectedOperation.length) {
      errorArr.push("operation");
    }

    if (errorArr.length) {
      setError(errorArr);
    } else {
      return true;
    }
  };

  const getProfile = () => {
    axios
      .get(`${ApiRoutes.auth.user.getProfile}?lang=${lang}`)
      .then((res) => {
        setData(JSON.parse(res.data.data));
        setLoad(false);

        if (JSON.parse(res.data.data) && JSON.parse(res.data.data).Picture) {
          setProfilePhoto(JSON.parse(res.data.data).Picture);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const updateProfile = (value, isSwitch) => {
    setEditLoad(true);
    axios
      .post(`${ApiRoutes.auth.user.editProfile}?lang=${lang}`, {
        name: isSwitch ? value.Name : value.name,
        surname: isSwitch ? value.Surname : value.surName,
        gender: isSwitch ? value.Gender : value.gender,
        phone: isSwitch ? value.Phone : value.phone,
        birthDate: isSwitch ? value.BirthDate : value.birthDate,
        country: isSwitch ? value.Country : value.region,
        provice: isSwitch ? value.Provice : value.provice,
        district: isSwitch ? value.District : value.district,
        address: isSwitch ? value.Address : value.address,
        website: isSwitch ? value.Website : value.website,
        instagram: isSwitch ? value.Instagram : value.instagram,
        mail: "",
        picture: "",
        password: "",
        isPhoneNot: value.IsPhoneNot,
        isEmailNot: value.IsEmailNot,
        isSocial: value.IsSocial,
      })
      .then((res) => {
        if (profilePhoto && typeof profilePhoto !== "string") {
          updateProfilePhoto(profilePhoto);
        } else {
          setSuccess("profile");
          setEdit(true);
          setEditLoad(false);
          getProfile();
        }
      })
      .catch((err) => {
        console.log(err);
        setEditLoad(false);
      });
  };

  const changePassword = (value) => {
    setEditLoad(true);
    axios
      .post(`${ApiRoutes.auth.user.passwordChange}?password=${value.password}`)
      .then((res) => {
        setSuccess("password");
        setEditLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setEditLoad(false);
      });
  };

  const updateProfilePhoto = (value) => {
    setEditLoad(true);
    let formData = new FormData();
    formData.append(userData.TypeId === 2 ? "files" : "file", value);
    axios
      .post(
        `${
          userData.TypeId === 2
            ? ApiRoutes.auth.doctor.addFile
            : ApiRoutes.auth.user.updateProfilePhoto
        }?lang=${lang}&id=0&caption=profilePhoto`,
        formData
      )
      .then((res) => {
        setSuccess("profile");
        setEditLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setEditLoad(false);
      });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess();
      }, 3000);
    }
  }, [success]);

  return (
    <>
      {isLoad ? (
        <Loading />
      ) : (
        <>
          {userData.TypeId === 2 && !isEdit ? (
            <DoctorDetail
              editProfile={() => setEdit(true)}
              currentId={data.doctorProfile.doctor.Id}
              profile
            />
          ) : (
            <div className={style.profileContainer}>
              <Sidebar
                isOpen={sideBar || width > 940}
                closeClick={() => setSidebar(false)}
                searchTrigger={() => {}}
              />
              <TopBar hamburgerClick={() => setSidebar(true)} />

              <div className={style.profileWrapper}>
                <div className={style.top}>
                  <div className={style.left}>
                    {userData.TypeId === 2 ? (
                      <h2 className={style.seperatorTitle}>
                        {LangData[lang].profile.userProfile}
                      </h2>
                    ) : null}

                    <Formik
                      initialValues={{
                        email: data.Mail,
                        surName: data.Surname,
                        name: data.Name,
                        phone: data.Phone,
                        region: data.Country ? data.Country : "",
                        provice: data.Provice ? data.Provice : "",
                        district: data.District ? data.District : "",
                        address: data.Address ? data.Address : "",
                        birthDate: data.BirthDate ? data.BirthDate : "",
                        gender: data.Gender,
                        instagram: data.Instagram ? data.Instagram : "",
                        website: data.Website ? data.Website : "",
                      }}
                      // validationSchema={PaymentSchema}
                      validationSchema={RegisterSchema}
                      onSubmit={(values) => {
                        updateProfile({
                          ...values,
                          IsPhoneNot: data.IsPhoneNot,
                          IsEmailNot: data.IsEmailNot,
                          IsSocial: data.IsSocial,
                        });
                        // loginSubmit(values);
                        // createPayment(values);
                      }}

                      // innerRef={formikRef}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form id="profile" onSubmit={handleSubmit}>
                          <label className={style.photo}>
                            <input
                              type="file"
                              onChange={(e) =>
                                setProfilePhoto(e.target.files[0])
                              }
                              accept="image/*"
                            />
                            <img
                              src={
                                profilePhoto && typeof profilePhoto === "string"
                                  ? profilePhoto
                                  : profilePhoto
                                  ? URL.createObjectURL(profilePhoto)
                                  : EmptyPhoto
                              }
                              alt="person"
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <ImageIcon /> {LangData[lang].profile.addPhoto}
                            </button>
                          </label>

                          <TextInput
                            name={"name"}
                            value={values.name}
                            title={LangData[lang].form.name}
                            placeholder={LangData[lang].form.namePlaceholder}
                          />
                          {errors.name && touched.name ? (
                            <span>{errors.name}</span>
                          ) : null}
                          <TextInput
                            name={"surName"}
                            value={values.surName}
                            title={LangData[lang].form.surname}
                            placeholder={LangData[lang].form.surnamePlaceholder}
                          />
                          {errors.surName && touched.surName ? (
                            <span>{errors.surName}</span>
                          ) : null}
                          {userData.TypeId === 2 ? (
                            <TextInput
                              name={"instagram"}
                              value={values.instagram}
                              title={LangData[lang].form.instagram}
                              placeholder={
                                LangData[lang].form.instagramPlaceholder
                              }
                            />
                          ) : null}
                          {errors.instagram && touched.instagram ? (
                            <span>{errors.instagram}</span>
                          ) : null}
                          {userData.TypeId === 2 ? (
                            <TextInput
                              name={"website"}
                              value={values.website}
                              title={LangData[lang].form.website}
                              placeholder={
                                LangData[lang].form.websitePlaceholder
                              }
                            />
                          ) : null}
                          {errors.website && touched.website ? (
                            <span>{errors.website}</span>
                          ) : null}
                          <SelectInput
                            name={"gender"}
                            value={values.gender}
                            title={LangData[lang].form.gender}
                            placeholder="Cinsiyet giriniz."
                            valueData={[
                              {
                                name: LangData[lang].form.man,
                                value: 0,
                              },
                              {
                                name: LangData[lang].form.woman,
                                value: 1,
                              },
                            ]}
                          />
                          <EmailInput
                            disabled
                            name={"email"}
                            value={values.email}
                          />
                          {errors.email && touched.email ? (
                            <span>{errors.email}</span>
                          ) : null}
                          <PhoneInput name={"phone"} value={values.phone} />
                          {errors.phone && touched.phone ? (
                            <span>{errors.phone}</span>
                          ) : null}
                          <DateInput
                            date={values.birthDate}
                            name={"birthDate"}
                            value={values.birthDate}
                            change={(e) => {
                              setFieldValue("birthDate", e);
                            }}
                          />
                          {errors.birthDate && touched.birthDate ? (
                            <span>{errors.birthDate}</span>
                          ) : null}
                          <RegionInput
                            name={"region"}
                            value={values.region}
                            title={LangData[lang].form.region}
                            placeholder={LangData[lang].form.regionPlaceholder}
                          />
                          <CityInput
                            name={"provice"}
                            value={values.provice}
                            title={LangData[lang].form.province}
                            placeholder={
                              LangData[lang].form.provincePlaceholder
                            }
                            data={values.region}
                          />
                          <TextInput
                            name={"district"}
                            value={values.district}
                            title={LangData[lang].form.district}
                            placeholder={
                              LangData[lang].form.districtPlaceholder
                            }
                          />
                          {errors.district && touched.district ? (
                            <span>{errors.district}</span>
                          ) : null}

                          <TextInput
                            name={"address"}
                            value={values.address}
                            title={LangData[lang].form.address}
                            placeholder={LangData[lang].form.addressPlaceholder}
                          />
                          {errors.address && touched.address ? (
                            <span>{errors.address}</span>
                          ) : null}

                          <button
                            disabled={editLoad || success}
                            type="submit"
                            className={`${style.submit} ${
                              success && success === "profile"
                                ? style.activeSubmit
                                : null
                            }`}
                          >
                            {editLoad ? (
                              <Spinner />
                            ) : (
                              LangData[lang].calendar.popup.save
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className={style.line} />
                  <div className={style.right}>
                    <Formik
                      initialValues={{
                        password: "",
                        passwordAgain: "",
                      }}
                      validationSchema={PasswordSchema}
                      onSubmit={(values) => {
                        changePassword(values);
                        // loginSubmit(values);
                        // createPayment(values);
                      }}

                      // innerRef={formikRef}
                    >
                      {({ errors, touched, values, handleSubmit }) => (
                        <Form id="password" onSubmit={handleSubmit}>
                          <PasswordInput
                            name={"password"}
                            value={values.password}
                          />
                          {errors.password && touched.password ? (
                            <span>{errors.password}</span>
                          ) : null}
                          <PasswordInput
                            name={"passwordAgain"}
                            value={values.passwordAgain}
                          />
                          {errors.passwordAgain && touched.passwordAgain ? (
                            <span>{errors.passwordAgain}</span>
                          ) : null}

                          <button
                            disabled={editLoad || success}
                            type="submit"
                            className={`${style.submit} ${
                              success && success === "password"
                                ? style.activeSubmit
                                : null
                            }`}
                          >
                            {editLoad ? (
                              <Spinner />
                            ) : (
                              LangData[lang].profile.changePassword
                            )}
                          </button>
                        </Form>
                      )}
                    </Formik>
                    <div className={style.switchWrapper}>
                      {userData.TypeId === 2 ? (
                        <label
                          className={`${style.switch} ${
                            data.IsSocial && style.active
                          }`}
                        >
                          <div className={style.circleWrapper}>
                            <div className={style.circle} />
                          </div>
                          <input
                            onChange={(e) => {
                              updateProfile(
                                {
                                  ...data,
                                  IsSocial: !data.IsSocial,
                                },
                                true
                              );
                            }}
                            type="checkbox"
                          />
                          <div className={style.textWrapper}>
                            <h5>{LangData[lang].form.socialmediaTitle}</h5>
                            <p>{LangData[lang].form.socialmediaText}</p>
                          </div>
                        </label>
                      ) : null}
                      <label
                        className={`${style.switch} ${
                          data.IsEmailNot && style.active
                        }`}
                      >
                        <div className={style.circleWrapper}>
                          <div className={style.circle} />
                        </div>
                        <input
                          onChange={(e) => {
                            updateProfile(
                              {
                                ...data,
                                IsEmailNot: !data.IsEmailNot,
                              },
                              true
                            );
                          }}
                          type="checkbox"
                        />
                        <h5>{LangData[lang].profile.emailNotification}</h5>
                      </label>
                      <label
                        className={`${style.switch} ${
                          data.IsPhoneNot && style.active
                        }`}
                      >
                        <div className={style.circleWrapper}>
                          <div className={style.circle} />
                        </div>
                        <input
                          onChange={(e) => {
                            updateProfile(
                              {
                                ...data,
                                IsPhoneNot: !data.IsPhoneNot,
                              },
                              true
                            );
                          }}
                          type="checkbox"
                        />
                        <h5>{LangData[lang].profile.phoneNotification}</h5>
                      </label>
                    </div>
                  </div>
                </div>
                {userData.TypeId === 2 ? (
                  <>
                    <DoctorForm data={data} lang={lang} />
                  </>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
